import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core';
import { GLOBAL_CLASS_BODY_BACKGROUND_WHITE } from '@boels-shared/constants/global.const';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { TrustHtmlPipe } from '@boels-shared/pipes/trust-html.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { ButtonOldComponent } from '@boels-experience/forms';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  standalone: true,
  imports: [LottieComponent, RouterLink, TranslateModule, TrustHtmlPipe, ButtonOldComponent],
})
export class NotFoundComponent implements OnDestroy {
  public readonly options: AnimationOptions = {
    path: 'assets/lottie/lottie-404.json',
  };

  constructor(@Inject(DOCUMENT) private readonly document: Document) {
    this.document.body.classList.add(GLOBAL_CLASS_BODY_BACKGROUND_WHITE);
  }

  public ngOnDestroy(): void {
    this.document.body.classList.remove(GLOBAL_CLASS_BODY_BACKGROUND_WHITE);
  }
}
